.mission-container {
  position: relative;
  /* background-image: url("../../public/images/banners/banner-mision-vision.png"); */
  background-size: auto;
  background-position: center;
  width: 86vw;
  height: 80vh;
  margin: auto;
  /* border: solid red 3px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* opacity: 0;
  transition: opacity 0.1s ease-in-out; */
}

.mission-header {
  /* border: solid green 3px; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  height: 18%;
}

.mission-header > div {
  /* border: solid red 3px; */
  display: flex;
  justify-content: center;
  align-content: center;
}

.header-text {
  width: 40%;
  opacity: 0;
  transition: opacity 0.3s;
}

.header-text > img {
  width: 100%;
  object-fit: contain;
}

.header-arrow-one > img,
.header-arrow-two > img {
  width: 75%;
  object-fit: contain;
}

.header-arrow-one,
.header-arrow-two {
  opacity: 0;
  transform: translateX(-5vw);
  transition: transform 0.6s, opacity 0.3s;
}

.mission-main {
  /* border: solid blue 3px; */
  width: 100%;
  height: 100%;
  padding: 2vh 6vw;
}

.mission-main > div {
  margin-bottom: 3vh;
}

.main-line-1 {
  margin-top: 5vh;
  opacity: 0;
  transform: translateX(10vw);
  transition: transform 0.4s, opacity 0.3s;
}

.main-line-2 {
  margin-left: 10vw;
  opacity: 0;
  transform: translateX(10vw);
  transition: transform 0.6s, opacity 0.3s;
}
.main-line-3 {
  margin-left: 22vw;
  opacity: 0;
  transform: translateX(10vw);
  transition: transform 0.8s, opacity 0.3s;
}
.main-line-4 {
  margin-left: 30vw;
  opacity: 0;
  transform: translateX(10vw);
  transition: transform 0.9s, opacity 0.3s;
}

.main-line-1 > img,
.main-line-2 > img,
.main-line-3 > img,
.main-line-4 > img {
  width: 36vw;
}
.side-arrows-container {
  /* border: solid red 2px; */
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.side-arrows-container > div {
  margin-top: 39vh;
  width: 50%;
  height: 50%;
}

.left-arrows {
  /* border: solid green 2px; */
  display: flex;
}

.bigger-arrow > img {
  width: 40vh;

  object-fit: contain;
}

.bigger-arrow {
  opacity: 0;
  margin-top: 2vh;
  margin-left: 0vw;
  transform: translateX(-5vw);
  transition: transform 0.5s ease-in-out, opacity 0.3s;
}

.smaller-arrow > img {
  width: 12vh;

  object-fit: contain;
}

.smaller-arrow {
  opacity: 0;
  margin-top: 24vh;
  margin-left: -5vw;
  transform: translateX(-5vw);
  transition: transform 0.5s ease-in-out, opacity 0.3s;
}

.big-arrow-right > img {
  width: 25%;
  margin-left: 60%;
  margin-top: 5%;
  object-fit: contain;
}

.big-arrow-right {
  opacity: 0;
  transform: translateX(-10vw);
  transition: transform 0.8s ease-in-out, opacity 0.3s;
}

.mission-visible {
  opacity: 1;
  transform: translateX(0vw);
}
