.language-container {
  font-size: 1vw;

  position: relative;
  border-radius: 2vw;
  height: 2.3vw;
  background-color: rgba(255, 255, 255, 0.138);

  cursor: pointer;
}

.text-container {
  width: 4.8vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5vw 0.7vw;
  margin: auto 0;
  align-content: center;
  border: solid rgba(255, 0, 0, 0) 2px;
}

.ball {
  /* border: solid rgb(58, 152, 89) 3px; */
  background-color: rgb(58, 152, 89);
  position: absolute;
  width: 2.3vw;
  height: 2.3vw;
  border-radius: 100%;
  top: 0px;
  right: 2.5vw;

  transition: right 0.3s ease-in-out;
}
.checked {
  right: 0;
  transition: right 0.3s ease-in-out;
}
