.main__container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 30vw;
  top: 0;
}

.search__box__container {
  display: flex;
}

.search {
  transition: transform 0.3s ease;
  max-width: 30vw;
  display: flex;
}

.search__wrapper {
  position: relative;
  max-width: 100%;
}

.search__field {
  max-width: 100%;
  border: none;
  cursor: text;

  width: 50px;
  height: 50px;
  color: transparent;
  font-family: Lato, sans-serif;
  font-size: 1.35em;
  padding: 0.35em 50px 0.35em 0;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.3s ease-in-out;
}

.search__field:focus {
  border-bottom-color: #ccc;
  width: 50vw;
  color: #2b2b2b;
  cursor: text;
  background-color: white;
  border: none;
  padding: 0 1vw;
}

.search__field:focus ~ .search__icon {
  background-color: transparent;
  cursor: default;
  pointer-events: auto;
}

.search__icon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #e9f1f4;
  width: 50px;
  height: 50px;
  font-size: 1.35em;
  text-align: center;
  border-color: transparent;
  border-radius: 50%;
  pointer-events: none;
  display: inline-block;
  transition: background-color 0.2s ease-in-out;
}

.search__field::-webkit-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition: top, color 0.1s;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
}

.search__field:-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition: top, color 0.1s;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
}

.search__field::-moz-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition: top, color 0.1s;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
}

.search__field:-ms-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition: top, color 0.1s;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
}

.search__field::-webkit-input-placeholder[style*="hidden"],
.search__field:-moz-placeholder[style*="hidden"],
.search__field::-moz-placeholder[style*="hidden"],
.search__field:-ms-input-placeholder[style*="hidden"] {
  color: #83b0c1;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
