/* CardsContainer.module.css */

.links-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: solid rgba(0, 128, 0, 0) 3px;
  /* margin-top: -3rem; */
  justify-content: space-between;
  /* border: solid red 3px; */
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: default;
  width: 100%;
  /* border: solid green 3px; */

  opacity: 1;
  transition: opacity 0.3s;
  height: 100%;
  min-height: 100%;
}

.switch {
  opacity: 0;
}

.arrow-container {
  /* border: solid purple 2px; */
  z-index: 100;
  display: flex;
  min-width: 4.4vw;
  justify-content: space-between;
  height: 2.2vw;
  max-height: 2.2vw;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
  margin-top: 1vw;
}

@media screen and (max-width: 1600px) {
  /* .arrow-container {
    margin-bottom: -2rem;
  } */
}

.visible {
  opacity: 1;
}

.arrow-hidden {
  /* border: solid purple 2px; */
  opacity: 0; /* Set opacity to 0 for hidden state */
}

.arrowDisabled {
  cursor: pointer;
  fill: #45b36a28;
  margin: 0 0.5rem;

  user-select: none;
  height: 1rem;
  width: 1rem;
  transition: height 0.2s ease-in-out, width 0.2s ease-in-out,
    fill 0.2s ease-in-out; /* Add a smooth transition effect */
}

.arrow {
  cursor: pointer;
  fill: #45b36a;
  margin: 0 0.5rem;
  user-select: none;
  height: 1rem;
  width: 1rem;
  transition: fill 0.2s ease-in-out, transform 0.2s ease-in-out; /* Add a smooth transition effect */
}

.arrow:active {
  transform: scale(1.3);
}
