/* .login-container {
  border: solid rgba(0, 0, 255, 0);
  border-bottom: #060606;
  height: 100vh;
  background-image: linear-gradient(0deg, #0b0e15 0%, #29374d 100%);
  display: flex;
} */

.alt-bg {
  background-color: #060606;
  border: solid rgba(0, 0, 255, 0);
  border-bottom: #060606;
  height: 100vh;
  display: flex;
}

.login {
  border: solid rgba(217, 217, 217, 0.076) 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  margin-top: 20vh;
  border-radius: 1rem;
  background-color: #ffffff14;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 7rem;
  padding-top: 6rem;
}

h1 {
  margin-bottom: 10%;
  color: #ffffffe6;
  text-align: center;
  font-family: Inter;
  font-size: 2.5rem;
  font-style: normal;
  padding-bottom: 0.5rem;
}
