.search__component {
  padding: 0 3vw;
  height: 2.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search__component svg {
  width: 1.6vw; /* Adjust the size as needed */
  height: 1.6vw; /* Adjust the size as needed */
  cursor: pointer;
}

.icon {
  transition: color 0.2s ease-in-out;
}

.icon:hover {
  color: #4cb66f;
}
