/* Contact.module.css */

.container {
  position: relative;
  display: flex;
  padding: 9vh 2vw 3vw 2vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  align-self: stretch;
  width: 40rem;
  margin: auto;

  border-radius: 1.25rem;
  background: var(
    --portfolio-henna-five-90-vercel-app-1920-x-951-default-nero,
    #ffffff0c
  );
  box-shadow: 0px 6px 15px 0px #000000;
  background-color: #111721fb;

  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 1;
  transform: translate(-50%, -50%) translateY(-10px); /* Adjust translateY for elevation */
}

.container.hidden {
  /* Add styles for when the component is hidden */
  opacity: 0;
  transform: translate(-50%, -50%) translateY(0); /* Bring the container back down */
  margin-left: 100vw;
}

.icon-container {
  /* border: solid red 2px; */
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 2.5rem 1.5rem;
  width: 3rem;
  display: flex;
  justify-content: center;
}

.exit {
  fill: #ffffffc6;
  width: 1.2rem;
  height: 1.2rem;
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out,
    fill 0.2s ease-in-out;
}

.exit:hover {
  /* width: 1.4rem;
  height: 1.4rem; */
  fill: #45b36a;
}

.exit:active {
  width: 1.4rem;
  height: 1.4rem;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.first-row {
  /* border: solid red 2px; */
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.first-row input {
  /* border: solid red 2px; */
  width: 100%;
}

input:focus {
  /* Your styles for the input field after click (focus) go here */
  border: 2px solid #45b36aec; /* Change the border color to blue when focused */
  outline: none; /* Remove the default focus outline */
}

textarea:focus {
  /* Your styles for the input field after click (focus) go here */
  border: 2px solid #45b36aec !important; /* Change the border color to blue when focused */
  outline: none !important; /* Remove the default focus outline */
}

.first-row :nth-child(2) {
  margin-left: 2rem;
}

.second-row {
  /* border: solid red 2px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 3rem;
}
.second-row :nth-child(1) {
  margin-bottom: 2rem;
}

.text-box {
  display: flex;
  height: 2.375rem;
  padding: 0.625rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 0.5rem;
  border: 2px solid
    var(
      --portfolio-henna-five-90-vercel-app-1920-x-951-default-mercury,
      #93929217
    );
  transition: border 0.2s ease-in-out;
}

.text-area {
  display: flex;
  padding: 0.625rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-top: 1rem;

  border-radius: 0.5rem;
  border: 2px solid
    var(
      --portfolio-henna-five-90-vercel-app-1920-x-951-default-mercury,
      #fffffff4
    );
  transition: border 0.2s ease-in-out;
}

textarea {
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border: #fffffff4;
  border-radius: 0.4rem;
  min-height: 4rem;
  background-color: #ffffffe3;
  transition: background-color 0.2s ease-in-out;
}

input {
  display: flex;
  padding-right: 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffffe3;
  transition: background-color 0.2s ease-in-out;
}

textarea:focus,
input:focus {
  background-color: #ffffff;
}

.sent {
  position: absolute;
  top: 0px;
  /* border: solid red 3px; */
  margin-top: 4rem;
}
