.card {
  display: flex;
  flex-wrap: nowrap;
  padding: 6% 8%;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  border: solid rgba(0, 128, 0, 0) 1px;

  fill: #ffffff;
  margin-left: 2vw;

  margin-bottom: 2vw;

  height: 18vw;

  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out,
    fill 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.hidden-top {
  transform: translate(0, -10rem);
  opacity: 0;
}
.hidden-right {
  transform: translate(10rem, 0);
  opacity: 0;
}
.hidden-bottom {
  transform: translate(0, 10rem);
  opacity: 0;
}
.hidden-left {
  transform: translate(-10rem, 0);
  opacity: 0;
}

.visible {
  opacity: 1;
  transform: translate(0, 0);
}

.card:hover {
  background-color: #101721;
  transform: scale(1.05); /* Scale up slightly when hovered */
  border: solid #45b36a 1px;
  fill: #45b36a;
}

.icon-container {
  position: absolute;

  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid red 3px; */

  /* Transition to smoothly change fill on hover */
}

/* .icon-container image {
  width: 100%;
  height: 100%;
  object-fit: fill;
} */

.card-title {
  margin-top: 5vw;
  color: #fff;
  font-family: Inter;
  /* border: solid red 2px; */
  /* font-size: 150%; */

  font-size: 1.25vw;

  font-style: normal;
  font-weight: 600;

  min-height: 3.2vw;
}

.card-subtitle {
  margin-top: 0.8vw;

  color: #fff;
  font-family: Heebo;

  font-size: 0.835vw;
  font-style: normal;
  font-weight: 300;
  line-height: 1rem; /* 160% */
  min-height: 4vw;
  max-height: 4vw;

  max-width: 15vw;
}

.unfinished {
  color: rgb(255, 166, 0) !important;
  border-bottom: 1px solid rgb(255, 166, 0) !important;
}

.card-link {
  position: absolute;
  bottom: 1.4vw;
  color: #45b36a;
  text-align: center;
  font-family: Heebo;
  font-size: 0.9vw;

  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 160% */
  border-bottom: 1px solid #45b36a;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (min-width: 1700px) {
  /* .card {
    min-width: 20vw;
    max-width: 20vw;
    min-height: 19vw;
    max-height: 19vw;
  } */
}

@media screen and (max-width: 1600px) {
  /* .card {
    min-height: 22vw;
    max-height: 23vw;

  } */

  /* .card-title {
    font-size: 120%;
  }

  .icon-container {
    position: absolute;
    max-width: 5vw;

    max-height: 5vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  } */
}
