.outer-card-container {
  overflow: scroll;
  overflow-y: hidden;
  background-color: rgba(254, 254, 254, 0.086);
  padding-left: 1rem;
  padding-right: 1rem;
  margin: auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.cards-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 1rem;
  /* border: solid blue 2px; */
}

.section-container {
  margin-left: 1.6rem;
  color: #45b36a;
  font-family: Inter;
  font-size: 0.847vw;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4rem; /* 160% */
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
  cursor: default;

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.visible {
  opacity: 1;
}

.image-container {
  margin: 1rem 0;
  filter: brightness(0.7);
  width: 100%;
  transition: filter 0.3s ease-in-out;
  background-color: white;
}

.image-container:hover {
  filter: brightness(1);
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* border: solid red 2px; */
}

.custom-carousel-icon-left {
  fill: #45b36a !important;
  user-select: none;
  height: 1rem;
  width: 1rem;
}

.custom-carousel-icon-right {
  position: absolute;
  fill: #45b36a !important;
  user-select: none;
  height: 1rem;
  width: 1rem;
}
