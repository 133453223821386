.dashboard__container {
  /* border: solid red 2px; */
  display: flex;

  justify-content: center;
}

.main__container {
  margin-top: 10vw;
  max-width: 40vw;
  min-width: 40vw;

  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* border: solid red 2px; */
}

.title {
  margin-bottom: 3vw;
  align-self: center;
  font-size: large;
}

select {
  margin-bottom: 2vw;
}

.form__select:focus {
  border-color: #45b36acb !important;
  box-shadow: 0 0 0 0.2rem #45b36acb !important; /* Opcional, para agregar un efecto de sombra */
  outline: none !important;
}

.button {
  border-color: #45b36acb !important; /* Color del borde en foco */
  background-color: #45b36acb !important;
}

.button:hover {
  background-color: #ffffff !important; /* Change background color on hover */
  color: #45b36a; /* Change text color on hover */
  border-color: #ffffff !important;
}
