.icon {
  color: rgba(255, 255, 255, 0.876);
  height: 0.9vw;
  margin-right: 3%;
  padding-bottom: 1%;
  transition: color 0.2s ease-in-out;
}

.icon:hover {
  color: #ffffff;
}
