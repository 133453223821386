.results__container {
  margin-top: 3vh;
  padding: 0;

  overflow-y: scroll;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  max-height: 30vh;
}

.results__container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.result__container {
  color: rgba(0, 0, 0, 0.866);
  background-color: rgb(255, 255, 255);
  margin-top: 0.3vw;
  padding: 0.5vw 0.5vw;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result__title {
  color: rgba(0, 0, 0, 0.842);
  font-family: Heebo;
  padding: 0 0.5vw;

  font-size: 1vw;
  font-style: normal;
  font-weight: bold;
  line-height: 1rem;
  height: 3vh;
}

.result__subtext {
  margin-top: 0.6vw;
  color: rgba(0, 0, 0, 0.657);
  font-family: Heebo;
  padding: 0 0.5vw;

  font-size: 1vw;
  font-style: normal;
  font-weight: bold;
  line-height: 1rem;
  height: 3vh;
}
