.outer-herramientas-container {
  padding-top: 1.3rem;
  margin-top: 8rem;
}

.herramientas-container {
  display: flex;
  flex-direction: column;
  max-width: 86vw;
  margin: auto;
  /* background-image: linear-gradient(0deg, #060606 0%, #111721 100%); */
  width: 100%;
  justify-content: center;
  margin-top: -11rem;

  padding-bottom: 10rem;
  top: 0px;
}
::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #060606;
}

::-webkit-scrollbar-thumb {
  background: #ffffff21;
  /* background: #3a9859; */
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #060606;
}

.title-container {
  max-width: 86vw;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-top: 5rem;
  margin-bottom: -8rem;
  padding: auto;
}

.title {
  color: white;
  text-align: center;
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4rem; /* 160% */
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  margin: 0px 0.5rem;
}

.line {
  flex: 1;
  border: 2px solid #45b36a; /* Customize the line appearance as needed */
  margin: 0 0rem;
}

.herramientas-category {
  /* border: solid yellow 2px; */
  margin-top: 3vw;
  padding-top: 9vw;
  margin-bottom: -4rem;
}

.no-scroll {
  overflow-x: hidden;
}
