.card-container {
  width: 27vw;
  cursor: pointer;
  height: 28vw;
  flex-shrink: 0;
  background-color: #17222fc4;
  /* background-image: linear-gradient(0deg, #060606 100%, #111721 100%); */

  border: solid #101822c4 1px;
  /* opacity: 0;
  transform: translateY(5rem); */
  transition: background-color 0.5s ease-in-out, border 0.6s ease-in-out,
    opacity 0.6s ease-in-out, transform 0.5s ease-out;
}

.card-visible {
  opacity: 1;
  transform: translateY(0);
}

.card-container:hover {
  border: solid #45b36aec 1px;
  /* background-color: #06060600; */
}

.card-container:hover .title {
  color: #45b36aec;
}

.image-container {
  width: 100.5%;
  height: 16vw;

  flex-shrink: 0;
}

.image-container img {
  margin-left: 0px;
  object-fit: fill;
  height: 100%;
  filter: brightness(0.7);
  width: 99.5%;
  transition: opacity 0.5s ease-in-out, filter 0.5s ease-in-out;
}

.card-container:hover img {
  filter: brightness(1);
}

.bottom-container {
  padding: 1rem;
}

.title {
  display: flex;
  width: 100%;
  min-height: 3.3rem;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;

  color: #fff;
  font-family: Inter;
  /* font-size: 1.3125rem; */
  font-size: 1.25vw;

  font-style: normal;
  font-weight: 600;
  line-height: 1.44375rem;
  margin-top: 0.5rem;

  transition: color 0.5s ease-in-out;
  /* border: solid purple 2px; */
}

.text-container {
  width: 100%;
  height: 10.35938rem;
  flex-shrink: 0;
  /* border: solid purple 2px; */

  color: #fff;

  font-family: Heebo;
  font-size: 0.847vw;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4rem; /* 160% */
}
