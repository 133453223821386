.card__form {
  display: flex;
  flex-direction: column;
  margin: 0 40vw;
  margin-top: 10vw;
  border: solid blue 2px;
}

.inputField {
  border: 2px solid transparent; /* Borde transparente cuando no está enfocado */
  transition: border-color 0.2s ease-in-out;
}

.inputField:focus {
  border-color: #45b36acb; /* Color del borde en foco */
  box-shadow: 0 0 0 0.2rem rgba(69, 179, 106, 0.25); /* Opcional, para agregar un efecto de sombra */
  outline: none;
}

.button {
  margin-top: 2vw;
  border-color: #45b36acb !important; /* Color del borde en foco */
  background-color: #45b36acb !important;
  right: 0px;
}

.button:hover {
  background-color: #ffffff !important; /* Change background color on hover */
  color: #45b36a; /* Change text color on hover */
  border-color: #ffffff !important;
}

.container {
  margin-top: -4vw;
  /* border: red 2px solid; */
  position: relative;
  margin-bottom: 7vw;
}

.back__arrow {
  max-width: fit-content;
  cursor: pointer;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.success {
  position: absolute;
  width: 100vw;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -30vw;
  min-height: 20vh;
}
