footer {
  /* border: solid red 2px; */
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 86vw;
  padding-top: 3vh;
  align-items: center;
  cursor: default;
}

a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.828) !important;
  cursor: pointer;
}

.line {
  flex: 1;
  border: 2px solid #45b36a; /* Customize the line appearance as needed */
  width: 100%;
  margin-bottom: 8vh;
}

.thinner-line {
  flex: 1;
  border: 1px solid #dbdbdb86; /* Customize the line appearance as needed */
  width: 100%;
}

.top-row {
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
  width: 100%;
  line-height: 1.5vw;
  color: rgba(255, 255, 255, 0.776);
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.text-container {
  font-size: 100%;
  text-wrap: nowrap;
  line-height: 1.4rem;
}

.text-container:hover {
  color: solid white 3px;
}

.title {
  color: rgba(255, 255, 255, 0.872);

  font-weight: bold;
  margin-bottom: 0.3rem;
}

.middle-row {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.middle-row > a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
}

.bottom-row {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1.5vw;
}

/* .division::before, */
.division {
  width: 1rem;
  height: 2rem;
  border-right: 1px solid #888888; /* Adjust color as needed */
  padding-right: 10px; /* Adjust spacing as needed */
  margin-right: 10px; /* Adjust spacing as needed */
}

.pointer {
  cursor: pointer;
}
