@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100&family=Heebo:wght@300;500&family=Inter:wght@300;400;600;700&family=Rubik:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  /* cursor: default !important; */
}

body {
  font-family: sans-serif;
  background-color: #060606;
  /* border: solid blue 3px; */
  overflow-x: hidden;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  overscroll-behavior: none;
}

.main-container {
  margin: auto;
  display: flex;

  flex-direction: column;
  width: 100%;

  align-items: center;
}

header {
  color: #eeeeef;
  position: fixed;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 0;
  z-index: 9999;
  max-width: 86vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 7vw;
}

/* header => nav-area => menus */
.nav-area {
  display: flex;
  background-color: #06060600;
  width: 100%;
  align-content: center;
  min-height: 6.8rem;
  height: 7vw;
}

.nav-area > ul {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90%;
  margin-left: 10%;
}

.logo {
  position: absolute;
  height: 100%;
  top: 0px;
  padding: 1% 2.5%;
}

.menus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  list-style: none;
  margin-right: 2rem;
  width: 60vw;
  height: 100%;
  white-space: nowrap;
}

.admin-menus {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  list-style: none;
  margin-right: 2rem;
  width: 60vw;
  height: 100%;
  white-space: nowrap;
  /* border: solid red 2px; */
}

.admin-menus > div {
  /* border: solid red 2px; */
  align-self: flex-start;
}

.routes-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  list-style: none;
  width: 55vw;
  white-space: nowrap;
  height: 100%;
  user-select: none;
}

.menu-item-container {
  position: relative;
  height: 8vw;
  display: flex;
}

.menu-items {
  position: relative;
  font-size: 1vw;

  margin-left: 0.95vw;

  margin-top: 3%;
}

.vertical-item-container {
  background-color: #0f0f11;
}

.vertical-menu-items {
  position: relative;
  font-size: 1vw;
  background-color: #0f0f11;
  border: solid #0f0f11 5px;
  margin-left: 0rem;
  margin-top: 0rem;
  transition: background-color 0.4s ease-in-out;
  border-radius: 10px;
}

.vertical-menu-items:hover {
  background-color: #333339;
}

.first-dropdown {
  margin-top: 7vw;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
  fill: white;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out,
    fill 0.25s ease-in-out; /* Add transitions */
}

.menu-items a:hover,
.menu-items button:hover {
  color: #45b36aec;
  fill: #45b36aec;
}

.down-arrow {
  margin-left: 0.6rem;
}

/* CSS for the Dropdown component */
.dropdown {
  position: absolute;
  padding: 0px;
  font-size: 0.9375rem;
  z-index: 9999;
  min-width: 10rem;
  list-style: none;
  border-radius: 2px;
  visibility: hidden; /* Initially, the dropdown is hidden */
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
}

.dropdown.show {
  visibility: visible; /* Make the dropdown visible */
  opacity: 1; /* Make the dropdown gradually appear */
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -5px;
  border-radius: 2px;

  padding: 0px;
  padding-top: 0px;
}

/* content */

.content {
  width: 100%;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}

/* Home Component */

.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

/* Background image in Home component*/

/*STARTS HERE */

.image-container {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  top: -5.2rem;

  z-index: -2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 1rem;
  transition: width 1.1s ease, height 1.1s ease, opacity 1.1s ease,
    top 1.1s ease, padding 1.1s ease;
  max-width: 100%;
}

.loaded {
  opacity: 1;
  transition: width 1.1s ease, height 1.1s ease, opacity 1.1s ease,
    top 1.1s ease;
}

.image-container::before {
  content: "";
  position: absolute;

  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.8),
    #060606,
    #060606
  );
}

.image-container img {
  object-fit: fill;
}

/*ENDS HERE*/

/* Text in Home Component*/

.intro {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.hero {
  position: relative;
  top: 23vh;
  margin: auto;
  min-width: 50vw;
  min-height: 26.5vw;
  max-height: 26.5vw;
  max-width: 50vw;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loaded-hero {
  opacity: 1;
}

.logo-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.logo-container a {
  margin: auto;
  text-decoration: none;
  color: #fff;
}

.logo-container img {
  width: 6vw;
  height: 6vw;
  margin-top: 2.9vh;
  margin-bottom: 0.5vh;
}

.logo-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  transition: color 0.25s ease-in-out;
}

.logo-text > div:nth-child(1) {
  font-family: Heebo;
  font-size: 1.22vw;
  line-height: 1vw;
}
.logo-text > div:nth-child(2) {
  font-family: Inter;
  font-size: 1vw;
}

.powered {
  position: absolute;
  color: #ffffffc7;
  font-family: Inter;
  font-size: 0.95vw;

  bottom: 4vh;
  cursor: default;
}

.powered span {
  font-weight: bold;
}

.team-banner {
  color: #45b36a;
  text-align: center;
  font-family: Inter;
  font-size: 0.87vw;

  font-style: normal;
  font-weight: 300;
  line-height: 2.3vh;

  letter-spacing: 0.125vw;

  text-transform: uppercase;
  margin-bottom: 1.58vh;
}

.title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 4.3vw;
  max-width: 45vw;
  width: 45vw;

  font-style: normal;
  font-weight: 700;
  line-height: 8vh;

  letter-spacing: -0.12vw;
  margin-bottom: 3.35vh;
}

.subtitle {
  color: #ffffffe6;
  text-align: center;
  font-family: Inter;
  font-size: 1.43vw;
  max-width: 40vw;
  font-style: normal;
  width: 40vw;
  margin: auto;
  line-height: 3.3vh;
}

@media screen and (max-width: 1600px) {
  .hero {
    top: 25vh;
    margin: auto;
    min-width: 50vw;
    min-height: 25vw;
    max-height: 25vw;
    max-width: 50vw;
  }
}
.restricted {
  bottom: 3vh;
  right: 2vw;
  cursor: default;
  position: fixed;
  width: 9rem;
  color: rgba(255, 140, 0, 0.442);
  font-weight: bold;
  font-size: 1.24vw;

  z-index: 2000;
}

.category-scroll {
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Loading spinner*/

@keyframes ldio-uvxcd6wzxfj {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-uvxcd6wzxfj div {
  position: absolute;
  width: 92.46px;
  height: 92.46px;
  border: 20.099999999999998px solid #4cb66f;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-uvxcd6wzxfj div {
  animation: ldio-uvxcd6wzxfj 0.6896551724137931s linear infinite;
  top: 100.49999999999999px;
  left: 100.49999999999999px;
}
.loadingio-spinner-rolling-2zou7lmg8qf {
  width: 201px;
  height: 201px;
  display: inline-block;
  overflow: hidden;
  background: none;
  margin: auto;
}
.ldio-uvxcd6wzxfj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-uvxcd6wzxfj div {
  box-sizing: content-box;
}

/* DOWN ARROW*/

@keyframes downarrow {
  0% {
    transform: translateY(0);
    opacity: 0.6;
  }

  100% {
    transform: translateY(0.2em);
    opacity: 1;
  }
}

.container-arrow-mission {
  position: absolute;
  margin: auto;
  bottom: 10vh;
  width: 9vh;
  left: 47.6vw;
}

.arrow-mission {
  border-color: transparent;
  border-style: solid;
  border-width: 0 2em;
  display: block;
  height: 0;
  text-indent: -9999px;
  width: 0;
}

.down-mission {
  animation: downarrow 0.6s infinite alternate ease-in-out;
  border-top: 2em solid #45b36a;
}

.downward-arrow {
  width: 8vh;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: downarrow 0.6s infinite alternate ease-in-out;
}
