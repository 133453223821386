.banner-container {
  max-width: 86vw;
  height: 7rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 6rem;
  margin-bottom: -5rem;
  z-index: 1;
  padding-top: 3rem;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  /* border: solid red 2px; */
}

.banner-visible {
  opacity: 1;
}

.title {
  color: white;
  text-align: center;
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4rem; /* 160% */
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin: 1rem 0rem;
  cursor: default;

  padding: 0 1.5rem;
}

.line {
  flex: 1;
  border: 2px solid #45b36a; /* Customize the line appearance as needed */
}
