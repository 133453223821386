.icon {
  color: rgba(255, 255, 255, 0.766);
  height: 2rem;
  width: 2rem;

  padding: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;

  transition: color 0.2s ease-in-out, border 0.2s ease-in-out;
  border: solid rgba(255, 255, 255, 0.766) 2px;
  border-radius: 100%;
  cursor: pointer;
}

.icon:hover {
  border: solid rgb(255, 255, 255) 2px;
}
