.outer-category-container {
  width: 86vw;

  max-width: 86vw;
  margin: auto;
  padding: 9vw 0rem;
  margin-top: 4vw;
  margin-bottom: 5vw;
  max-height: 50vw;
  min-height: 50vw;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.category-container {
  /* border: solid blue 3px; */
  min-height: 50vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem; /* Adjust the gap between columns */
  width: 100%;
}

.category-container > div {
  /* border: solid red 2px; */
  width: 100%;
  height: 80%;
}

.shorter-margin {
  /* border: solid red 3px; */
  margin-bottom: 0;
}
