.carousel-control-next {
  /* border: solid red 3px; */
  max-width: 2vw;
  margin-right: -2vw !important;
}

.carousel-control-prev {
  /* border: solid red 3px; */
  max-width: 2vw;
  margin-left: -2vw !important;
}

.carousel-indicators {
  bottom: -4vw !important;
  /* border: solid red 3px; */
}
